/* global $ */
import { map, partial, unescape, take } from 'lodash-es'

const helpers = {
  // The `read` methods return promises (or jQuery's approximation, anyway) so
  // that the calling code can register additional callbacks with `then` to
  // react to asynchronous feed reading code however it deems appropriate. If
  // for some reason the feed reading is called for without ensuring the element
  // to display results in exists, returning `nullPromise` instead of nothing
  // ensures that any `then` calls chained onto that response will safely do
  // nothing rather than throw errors
  nullPromise: {
    then: function () { return this }
  },
  handleError: function (err) {
    console.warn(err)
  },
  xmlFragmentTagContents: function (itemXML, tagName) {
    return $(itemXML).find(tagName).html()
  },
  stripCDATATag: function (string) {
    const OPEN_TAG = /^<!\[CDATA\[/
    const CLOSE_TAG = /]]>$/

    return string.replace(OPEN_TAG, '').replace(CLOSE_TAG, '')
  }
}
const emojiFoundation = function ($el) {
  return {
    $el,
    url: 'http://www.emojifoundation.com/feed/',
    read: function () {
      if (!this.$el.exists()) return helpers.nullPromise

      this.$el.on('latestPostLoaded', (_event, postData) => {
        this.$el.html(`<h4 class="panel-head">${postData.title}</h4><p class="panel-body">${postData.description}</p>`)
      })

      return this.latestPost()
    },
    latestPost: function () {
      return $.get(this.url).then((response) => {
        let latestItemXML = $(response).find('item')[0]
        let tagContents = partial(helpers.xmlFragmentTagContents, latestItemXML)
        let latestPostData = {}

        latestPostData.title = tagContents('title')
        latestPostData.description = helpers.stripCDATATag(tagContents('description'))

        this.$el.trigger('latestPostLoaded', latestPostData)
      }, helpers.handleError)
    }
  }
}
const emojiGoogleAlert = function ($el) {
  return {
    $el,
    url: 'get_feed.php?url=https://www.google.com/alerts/feeds/12443948085960038167/13887278284178250782',
    read: function () {
      if (!this.$el.exists()) return helpers.nullPromise

      const numberOfResultsToDisplay = 6

      this.$el.on('googleAlertResultsLoaded', (_e, ...resultsList) => {
        const resultsHTML = take(resultsList, numberOfResultsToDisplay)
              .map((result) => {
                return `<div class="emoji-resource col-sm-3">
                          <a href="${result.href}"><h4>${result.title}</h4></a>
                          ${result.content}
                        </div>`
              }).join('')
        const cleanedResults = unescape(resultsHTML).replace(/<\/?b>/g, '')

        this.$el.append(cleanedResults)
      })

      return this.latestGoogleAlertResults()
    },
    latestGoogleAlertResults: function () {
      return $.get(this.url).then((response) => {
        let resultsListXML = $(response).find('entry')
        let resultsData = map(resultsListXML, (result) => {
          let tagContents = partial(helpers.xmlFragmentTagContents, result)
          let href = $(result).find('link').attr('href')

          return {title: tagContents('title'), content: tagContents('content'), href}
        })

        this.$el.trigger('googleAlertResultsLoaded', resultsData)
      }, helpers.handleError)
    }
  }
}

const feedReader = {emojiFoundation, emojiGoogleAlert}

export default feedReader
