import twemoji from 'twemoji'

const options = {
  folder: 'svg',
  ext: '.svg'
}
const emojiParser = {
  parse: () => {
    const page = document.querySelector('body')

    twemoji.parse(page, options)
  },
  asCallback: (targetDomNode) => () => twemoji.parse(targetDomNode, options)
}

export default emojiParser
