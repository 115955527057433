/* global $ */

import Clipboard from 'clipboard'

function copyWriter (parentSelector) {
  const $parent = $(parentSelector)

  if (Clipboard.isSupported()) {
    const elementId = 'copy-emoji__btn'
    // TODO: fill in contents of copy button here
    const clipboard = new Clipboard(`#${elementId}`)

    $parent.append(`
        <span class="input-group-btn">
          <button type="button" id="copy-emoji__btn" class="btn btn-default copy-emoji__btn"  data-clipboard-target="#emoji-copy-data" >
            <i class="glyphicon glyphicon-copy"></i>
          </button>
        </span>`)

    clipboard.on('success', (e) => {
      const $this = $(e.trigger)

      $this.attr('data-original-title', 'Copied!')
        .tooltip('show')
        .one('mouseleave', () => $this.attr('data-original-title', ''))

      e.clearSelection()
    })

    // TODO: actually handle errors?
    clipboard.on('error', function (e) {
      console.error('Action:', e.action)
      console.error('Trigger:', e.trigger)
    })
  } else {
    // Make it easy to copy even when javascript can't do it for you
    $parent.mouseup((e) => {
      const $this = $(e.target)

      // Is copy command-c or control-c?
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0
      const metaKey = isMac ? '⌘' : 'Control'

      // select the emoji on any click, because clicking and dragging to
      // highlight a single character is lame
      $this.select()

      $this.attr('data-original-title', `Type ${metaKey}-C to copy!`)
        .tooltip('show')
        .one('mouseleave', () => $this.attr('data-original-title', ''))
    })
  }
}

export default copyWriter
