/* global $ */
/*
 * This file is included in every page of the site; so anything that is
 * page-specific should wrap its logic in an appropriate if-statement.
 *
 * Further, to keep this page clean, each significant piece of functionality
 * should be written as a self-contained module that exports a simple object
 * that will execute its feature with one or two method calls (ideally one).
 * This file is the place where that all gets orchestrated, so it should stay as
 * simple as possible.
 *
 * No object is imported from the file `plugins.js`, but it's still a necessary
 * import. Its code extends pre-existing global objects: for example, giving the
 * jQuery prototype a new `exists` function.
 */

import './plugins.js'
import feedReader from './feed_reader.js'
import emojiParser from './emoji_parser.js'
import copyWriter from './copy_writer.js'

const $emojiFoundationLatest = $('#emoji-foundation-latest')
const $emojiResources = $('#emoji-resources')
const $overlaytip = $('#overlaytip')
const copyBtnParentSelector = '#copy-emoji__wrapper'
const $copyEmoji = $('#copy-emoji')

emojiParser.parse()

if ($emojiFoundationLatest.exists()) {
  const parserCallback = emojiParser.asCallback($emojiFoundationLatest.get(0))
  feedReader.emojiFoundation($emojiFoundationLatest).read().then(parserCallback)
}

if ($emojiResources.exists()) {
  const parserCallback = emojiParser.asCallback($emojiResources.get(0))
  feedReader.emojiGoogleAlert($emojiResources).read().then(parserCallback)
}

if ($overlaytip.exists()) {
  $overlaytip.click(_e => $overlaytip.hide())
  $overlaytip.click() // TODO: remove before deploying to production
}

if ($copyEmoji.exists()) {
  copyWriter(copyBtnParentSelector)
}
